import '../../assets/styles/App.scss';
import ReactGA from "react-ga4";


const safeWindowOpener = (untrustedURL) => {
    var newWindow=window.open();
    newWindow.opener=null;
    newWindow.location=untrustedURL;
}

function TermsOfUse () {
    document.title = 'Terms and Conditions | Demand Response Direct Participation';
    ReactGA.send("pageview");
    return (
        <>
            <section className='hero-container'>
                <p><b style={{color: "#505050f0"}}>BY CLICKING "AUTHORIZE" YOU ARE AGREEING TO THE FOLLOWING TERMS AND CONDITIONS. PLEASE READ THEM CAREFULLY.</b></p>
                <p style={{color: "#525252"}}>These terms and conditions are subject to such modifications as the California Public Utilities Commission (CPUC) may direct from time to time in the exercise of its jurisdiction.</p>
                <p style={{color: "#525252"}}>You are agreeing to disclose your personal energy-related information to up to two collaborating Non-Utility Demand Response Providers (DRPs), pursuant to Electric Rule 32 (Rule 32) of SDG&amp;E®, so that you may obtain Demand Response services. You are also authorizing SDG&amp;E and such DRP(s) to take certain actions on your behalf in accordance with Rule 32. Rule 32 can be accessed at <a href="#" onClick={() => safeWindowOpener("http://regarchive.sdge.com/tm2/pdf/ELEC_ELEC-RULES_ERULE32.pdf") }>http://regarchive.sdge.com/tm2/pdf/ELEC_ELEC-RULES_ERULE32.pdf</a>. Capitalized terms used but not defined herein are defined in Rule 32.</p>
                <p style={{color: "#525252"}}>Once you authorize the DRP(s) to access your personal energy-related information, you are responsible for ensuring that the DRP(s) safeguard this information from further disclosure without your consent. This authorization for SDG&amp;E to release your information to one or more DRPs under Rule 32 is a separate agreement from the one you may have or may make with the DRP(s) for their services.</p>

                <p><b style={{color: "#505050f0"}}>SUMMARY OF INFORMATION AUTHORIZED TO BE RELEASED</b></p>
                <p style={{color: "#525252"}}>I, (Customer), authorize SDG&amp;E to disclose to the above DRP(s) for the service accounts listed above and/or attached to this form: 1) customer information (e.g., name, service account number, service address, rate schedule); 2) up to 36 months of historical and ongoing electric and gas meter data and/or monthly usage data; 3) current SDG&amp;E demand response programs in which I am known to participate; 4) historical energy efficiency program(s) in which I was qualified and paid; 5) the most recent 12 months of electric and gas rates for the current meter and notification of rate change; 6) All data elements identified in Section D of Electric Rule 32. These data elements may be modified with CPUC approval. Electric Rule 32 details the complete list of data elements authorized by the CPUC to be released to the DRP(s).</p>

                <p><b style={{color: "#505050f0"}}>ACTIONS YOU AUTHORIZE THE DRP(S) TO MAKE ON YOUR BEHALF</b></p>
                <p style={{color: "#525252"}}>You agree to grant the authorized DRP(s) permission to request that SDG&amp;E shorten the interval length of your electric meter(s), if such option is available to SDG&amp;E, when the DRP(s) have successfully registered your service account with the California Independent System Operator (CAISO), if SDG&amp;E is the Meter Service Provider. Such meter reprogramming may be necessary to enable your DRP to participate in the wholesale market.</p>
                <p style={{color: "#525252"}}>You also grant the authorized DRP(s) permission to revoke this authorization on your behalf. A revocation submitted by you or one of the authorized DRPs shall revoke this authorization for all of the other parties to this authorization. You, or your authorized DRP(s), may revoke this authorization at any time.</p>
                
                <p><b style={{color: "#505050f0"}}>ACTIONS YOU AUTHORIZE SDG&amp;E TO MAKE ON YOUR BEHALF</b></p>
                <p style={{color: "#525252"}}>You authorize SDG&amp;E to release your personal energy-related information to the DRP(s) even after the time period of this authorization has ended, limited to updates to the data for the time period during which this authorization was valid. In all cases, authorization to release personal energy-related information for a service account will be automatically revoked when the service account is closed by the customer.</p>
                <p style={{color: "#525252"}}>You acknowledge and agree that if you are on Critical Peak Pricing (CPP), then you will be scheduled to be unenrolled from CPP when your service account has been successfully registered in the CAISO’s Relevant Systems by one of the DRP(s).  You agree to bear any resulting financial consequences, including without limitation, loss of bill protection, incentive payments, and reimbursements to SDG&amp;E of incentives related to your participation in CPP, other SDG&amp;E DR programs, or DR enabling technology.</p>
                <p style={{color: "#525252"}}>If you are submitting this authorization on behalf of the Customer of Record, you declare that you are authorized to execute this authorization electronically on behalf of the Customer of Record, and that you have authority to financially bind the Customer of Record.</p>
                <p style={{color: "#525252"}}>You understand SDG&amp;E reserves the right to verify any authorization request submitted before releasing information or taking any action on your behalf.  You hereby release, hold harmless, and indemnify SDG&amp;E from any liability, claims, demands, and causes of action, damages, or expenses resulting from: (1) any release of information to the DRP(s) pursuant to this authorization; (2) the unauthorized use of this information by the DRP(s) or any other third party; and (3) any actions taken by the DRP(s) pursuant to this authorization.</p>
                <p style={{color: "#525252"}}>The start date for the release by SDG&amp;E of your personal energy-related information will be upon the completed processing of this authorization.  The end date for the release by SDG&amp;E of your personal energy-related information is the earlier of the following: (1) as specified in this authorization; (2) until you or one of the authorized DRP(s) revoke this authorization; or (3) when the service account is closed by the Customer. </p>
                <p style={{color: "#525252"}}>See SDG&amp;E&apos;s Privacy Policy at <a href="#" onClick={() => safeWindowOpener("https://www.sdge.com/customer-privacy/privacy-policy") }>www.sdge.com/customer-privacy/privacy-policy</a>.</p>
            </section>
        </>
    )
  }
  
  export default TermsOfUse;